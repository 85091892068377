import React, {useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import img_deleted_portfolio from '@image/img_stop_copy.png'
import img_deleted_portfolio_dark from '@image/img_deleted_portfolio_dark.png'
import {apiPostDeleteCopier} from '@api/copy/copier/deleteCopier'
import WizImage from '@component/images/WizImage'
import WizButton from '@component/buttons/WizButton'
import Spinner from '@component/Spinner/Spinner'
import WizContentModal from '@component/modal/WizContentModal'
import useDark from '@hook/useDark'
import useQueryGetUserCopyDashboard from '@hook/query/portfolio/copyfolio/useQueryGetCopyDashboard'
import useQueryGetCopierMineBot from '@hook/query/portfolio/copyfolio/useQueryGetCopierMineBot'

interface IProps {
    id: string

    onDeleteComplete: () => void
}

const DeleteStoppedCopierModal = ({id, onDeleteComplete}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    const [isDeleting, setIsDeleting] = useState(false)
    const {refresh} = useQueryGetUserCopyDashboard(id, undefined, true)
    const {refresh: copierMineRefresh} = useQueryGetCopierMineBot(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    const onDelete = async () => {
        setIsDeleting(true)
        try {
            await apiPostDeleteCopier(id)
            setIsDeleting(false)
            modalRef.current.close()
            refresh()
            copierMineRefresh()
            onDeleteComplete()
        } catch (e) {
            //TODO alert error
            setIsDeleting(false)
        }
    }

    const onClose = () => {
        modalRef.current.close()
    }

    return (
        <WizContentModal title={t('copyfolio.delete.modal.head')} ref={modalRef} size={'600'}>
            {isDeleting ? (
                <div className={'flex flex-col items-center justify-center min-h-[200px]'}>
                    <Spinner visible={isDeleting} size={'large'} />
                    <Text className={'mt-4 text-gray02 dark:text-dark_gray02'}>
                        {t('copyfolio.delete.modal.deleteprogress')}
                    </Text>
                </div>
            ) : (
                <div className={'flex flex-col gap-5 px'}>
                    <WizImage
                        src={isRenderDark ? img_deleted_portfolio_dark : img_deleted_portfolio}
                        height={200}
                        containerClassName={'mt-[30px] self-center'}
                    />
                    <ol className={'list-decimal mt-[10px] ml-[20px]'}>
                        <li className={'text-gray02 dark:text-dark_gray02'}>
                            <Text className={'inline'}>{t('copyfolio.delete.modal.line1')}</Text>
                        </li>
                        <li className={'text-gray02 dark:text-dark_gray02'}>
                            <Text className={'inline'}>{t('copyfolio.delete.modal.line2')}</Text>
                        </li>
                        <li className={'text-gray02 dark:text-dark_gray02'}>
                            <Text className={'inline'}>{t('copyfolio.delete.modal.line3')}</Text>
                        </li>
                    </ol>
                    <div className={'grid grid-cols-2 gap-2 mt-4'}>
                        <WizButton
                            text={t('copyfolio.delete.modal.cancelbutton')}
                            buttonType="border"
                            onClick={onClose}
                        />
                        <WizButton
                            text={t('copyfolio.delete.modal.deletebutton')}
                            buttonType="primary"
                            onClick={onDelete}
                        />
                    </div>
                </div>
            )}
        </WizContentModal>
    )
}

export default React.forwardRef(DeleteStoppedCopierModal)
