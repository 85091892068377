import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import {useRouter} from 'next/router'
import TagText from '@component/Text/TagText'

interface IProps {
    loss?: number
}

const LeaderCopierRecoveryModal = ({loss}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const router = useRouter()
    const {t} = useTranslation()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))
    return (
        <WizContentModal title={t('copyfolio.copyTrading.leader.copier.recovery.title')} ref={modalRef} size={'400'}>
            <div className={'pt-[10px]'}>
                <TagText
                    className={`text-body3 text-gray02 dark:text-dark_gray02 ${
                        router?.locale === 'ko' && 'break-all'
                    }`}>
                    {t('copyfolio.copyTrading.leader.copier.recovery.content')}
                </TagText>
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(LeaderCopierRecoveryModal)
